import React from 'react'
import {
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react'
import { StyledHomepageParagraph } from './styledComponents'
import SlideAnimation from './slideAnimation'

const A_KEY_NAMES = ["about-header-anim"];

export const Home = () => (
  <Segment id="home" style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column>
          <Header as='h3' style={{ fontSize: '2em' }}>
            About Us
          </Header>
          <StyledHomepageParagraph>
            Welcome to SRVIS, where engineering excellence meets your business aspirations. 
            With a decade of experience in the heart of the tech industry's powerhouse companies, our prowess lies in our ability to translate complex technological challenges into streamlined, effective solutions. 
            Our core strength is web development. We don't merely write code; we engineer bespoke digital solutions designed to enhance your business operations. 
            Specializing in a host of advanced technologies, we craft dynamic, responsive, and secure web applications that don't just meet your needs - they push the boundaries of what's possible.
          </StyledHomepageParagraph>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)