import styled from 'styled-components'
import { COLOR_PALLETE } from '../constants'
import { Header } from 'semantic-ui-react'

export const StyledHomepageParagraph = styled.p`
  font-size: 1.33em;
`

export const StyledHomepageList = styled.ul`
  list-style-type: none;
  padding: 0;

  li {
    border-left: 3px solid ${COLOR_PALLETE.pText};
    margin-bottom: 10px;
    padding-left: 10px;
  }
`
export const StyledSubHeading = styled(Header)`
  &&& {
    font-size: 1.33em;
  }
`