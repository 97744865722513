import React from 'react'
import {
  Grid,
  Header,
  Segment,
  Icon,
  List,
  Container
} from 'semantic-ui-react'
import SlideAnimation from './slideAnimation'

export const Footer = () => (
	<Segment inverted vertical style={{ padding: '5em 0em' }}>
		<Container>
			<Grid divided inverted stackable>
				<Grid.Row>
					<Grid.Column width={3}>
					<Header inverted as='h4' content='Socials' />
					<List link inverted>
							<List.Item as='a' href='https://github.com/srviscode'><Icon name='github' /></List.Item>
					</List>
					</Grid.Column>
					<Grid.Column width={3}>
					<Header inverted as='h4' content='Services' />
					<List link inverted>
							<List.Item as='a' href='mailto:info@srvis.io'>Get In Touch</List.Item>
					</List>
					</Grid.Column>
					<Grid.Column width={7}>
					<Header as='h4' inverted>
							Made with ❤️️
					</Header>
					<p>
							© 2023 SRVIS. All Rights Reserved.
					</p>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Container>
	</Segment>
)