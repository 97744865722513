import React, { useEffect, useRef } from "react";
import PropTypes from 'prop-types'

const A_FROM_LEFT = 'translateX(-100%)';
const A_FROM_RIGHT = 'translateX(100%)';

/**
 * TODOS:
 * - Spinny animation around picture: https://youtu.be/sQoiM7i5Nqc?t=1657
 * - Cool slidy animations in this vid: https://www.youtube.com/watch?v=S9NOXjdipl4
 * - Cool wheel animation: https://www.youtube.com/watch?v=sU-uzO7nNhI
 * - Solar system animation: https://www.youtube.com/shorts/LOSCrYq0ZB4?feature=share
 */

function SlideAnimation({ children, delay, direction }) {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
					let animDuration = 618;
					let animFrames = [
            // keyframes
            { transform:  A_FROM_LEFT},
            { transform: 'translateX(0)' }
          ];
					if (direction == 'left') {
						animFrames = [
							// keyframes
							{ transform:  A_FROM_RIGHT},
							{ transform: 'translateX(0)' }
						];

						if (delay && delay > 0) {
							animFrames.push({ transform: A_FROM_RIGHT, offset: delay/100 })
							animDuration = animDuration + delay
						}
					}

          entry.target.animate(animFrames, {
            // timing options
            duration: animDuration,
            easing: 'ease-in-out',
            fill: 'forwards',
          });
					observer.unobserve(entry.target);  // stop observing the target after starting the animation
        }
      },
      { threshold: 0.2 }  // triggers when at least 10% of the target is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
		<div ref={sectionRef} style={{ pointerEvents: 'auto' }}>
			{children}
		</div>
	);
}

SlideAnimation.propTypes = {
	children: PropTypes.node,
	delay: PropTypes.number,
	direction: PropTypes.string
}

export default SlideAnimation;