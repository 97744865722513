import React from 'react'
import PropTypes from 'prop-types'
import {
	Header,
	Container,
	Button,
	Icon
} from 'semantic-ui-react'
import SlideAnimation from './slideAnimation'
import { COLOR_PALLETE } from '../constants'
import styled, { keyframes } from 'styled-components'

const A_KEY_NAMES = [
	"main-header-anim",
	"main-header2-anim",
	"main-header3-anim"
]

const StyledAnimatedButton = styled(Button)`
  &&& {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 100%;
    border: .2rem solid ${COLOR_PALLETE.pText};
    z-index: 1;
    overflow: hidden;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: -1;
    transition: width 0.618s;
    background: ${COLOR_PALLETE.pText};
  }

  &:hover::before {
    width: 100%;
  }
`;

const StyledAnimatedHeaderColorFill = keyframes`
  0%,
  10%,
  100% {
    background-position: -33rem 0;
  }
  65%,
  85% {
    background-position: 0 0;
  }
`;

const StyledAnimatedHeaderCursorMovement = keyframes`
  0%,
  10%,
  100% {
    width: 0;
  }
  65%,
  78%,
  85% {
    width: 100%;
    opacity: 1;
  }
  75%,
  81% {
    opacity: 0;
  }
`;

const StyledAnimatedHeader = styled.div`
  h2 {
    position: relative;
    display: inline-block;
    color: transparent;
    font-size: ${props => props.mobile ? '1.5em' : '1.7em'};
    font-weight: 700;
    -webkit-text-stroke: .7px ${COLOR_PALLETE.pText};
    background-image: linear-gradient(${COLOR_PALLETE.pText}, ${COLOR_PALLETE.pText});
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    background-position: -33rem 0;
    margin: ${props => props.mobile ? '0.3em' : '0.7rem'};
    animation: ${StyledAnimatedHeaderColorFill} 6s linear infinite;
    animation-delay: 0.618s;
  }

  h2::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    z-index: 1;
    border-right: ${props => props.mobile ? '0px' : '2px'} solid ${COLOR_PALLETE.pText};
    animation: ${StyledAnimatedHeaderCursorMovement} 6s linear infinite;
    animation-delay: 0.618s;
  }
`

const HomepageHeading = ({ mobile }) => (
	<Container text>
		<Header
			as='h1'
			content='SRVIS'
			inverted
			style={{
				fontSize: mobile ? '2em' : '4em',
				fontWeight: 'normal',
				marginBottom: 0,
				marginTop: mobile ? '1.5em' : '3em',
			}}
		/>
		<StyledAnimatedHeader mobile={mobile}>
      <h2>Engineering Tomorrow's Web, Today</h2>
    </StyledAnimatedHeader>
		<StyledAnimatedButton href="#services" size='huge'>
      Our Services
      <Icon name='right arrow' />
    </StyledAnimatedButton>
	</Container>
)

HomepageHeading.propTypes = {
	mobile: PropTypes.bool,
}

export default HomepageHeading