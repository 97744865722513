import React, { useEffect, useRef } from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

// Style the ball and add a horizontal box-shadow as a trail
const Ball = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 50px 0 15px 5px red;  // initial box-shadow as a trail
`;

const BouncingBall = () => {
  const ballRef = useRef(null);

	useEffect(() => {
		if (ballRef.current) {
			const ballAnimation = [];

			for (let i = 0; i < window.innerWidth; i++) {
				let t = `translateX(${i}px)`;
				let b = `${Math.sin(i/50)*25}px`; // sine wave for smooth vertical oscillation
				let s = `-50px 0 ${50 - i % 50}px 5px red`;  // dynamically changing box-shadow
				ballAnimation.push({ transform: t, bottom: b, boxShadow: s });
			}

			const ballTiming = {
				duration: 5000,  // reduced for faster animation
				iterations: Infinity,
				easing: "linear"
			};

			ballRef.current.animate(ballAnimation, ballTiming);
		}
	}, []);

  return (
    <Container>
      <Ball ref={ballRef} />
    </Container>
  );
};

export default BouncingBall;
