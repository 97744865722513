import React from 'react'
import {
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react'
import { StyledHomepageParagraph } from './styledComponents'
import SlideAnimation from './slideAnimation'

const A_KEY_NAMES = ["contact-us-header-anim"];

export const Contact = () => (
  <Segment id="contact" style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
          <Grid.Column>
            <Header as='h3' style={{ fontSize: '2em' }}>
              Contact Us
            </Header>
            <StyledHomepageParagraph>
              We're excited to join you on your digital journey. For any inquiries or further information about our services, don't hesitate to reach out. 
              Email us at <a href='mailto:info@srvis.io'>info@srvis.io</a>, and we'll get back to you promptly. Let's build the future of your business together.
            </StyledHomepageParagraph>
          </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)