import React from 'react'
import {
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'
import { StyledHomepageList, StyledHomepageParagraph, StyledSubHeading } from './styledComponents'
import SlideAnimation from './slideAnimation'
import { COLOR_PALLETE } from '../constants'
import serviceImage from '../static/00655-3308190209.webp'

const A_KEY_NAMES = [
  "service-header-anim", 
  "styled-para-anim", 
  "styled-list-anim1",
  "styled-list-anim2",
  "styled-list-anim3",
  "styled-list-anim4",
  "styled-list-anim5",
  "image-anim",
  "closing-paragraph-anim"
]

const SERVICE_LIST = [
  {
    heading: "Typescript, Go, and C# Development:",
    body: "Leverage our in-depth understanding of these powerful languages to build robust, high-performing applications."
  },
  {
    heading: "React Native and React Development:",
    body: "From engaging web apps to high-performance mobile applications, trust our expertise to deliver immersive experiences with React and React Native."
  },
  {
    heading: "Database and Cloud Services:",
    body: "From traditional SQL databases to NoSQL alternatives, we offer comprehensive support for all your database needs. Furthermore, optimize your cloud operations with our expert assistance in Azure and AWS services."
  },
  {
    heading: "Custom Web Applications:",
    body: "We understand that every business is different. With this in mind, we design and develop custom web applications from scratch, offering the highest degree of flexibility and scalability."
  },
  {
    heading: "Crypto Applications and Smart Contracts:",
    body: "As the world of digital currencies grows, so do your opportunities. We're equipped to design and develop applications in the thriving field of cryptocurrencies and smart contracts, helping you tap into this promising avenue."
  }
]

export const Services = () => (
  <Segment id="services" style={{ padding: '8em 0em' }} vertical>
    <Grid container stackable verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h3' style={{ fontSize: '2em' }}>
            Our Services
          </Header>
          &nbsp;
          &nbsp;
          <StyledHomepageParagraph>At SRVIS, we understand that every business has unique needs, and we're committed to providing solutions that fit you just right. 
          Our services range across a wide spectrum of web development technologies and platforms. Here's what we offer:</StyledHomepageParagraph>
          &nbsp;
          &nbsp;
          <StyledHomepageList>
            {SERVICE_LIST.map((c, i) => (
              <li key={i}>
                <StyledSubHeading>{c.heading}</StyledSubHeading>
                <StyledHomepageParagraph>{c.body}</StyledHomepageParagraph>
              </li>
            ))}
          </StyledHomepageList>
          &nbsp;
          &nbsp;
        </Grid.Column>
        <Grid.Column floated='right' width={6}>
          <Image bordered rounded size='large' src={serviceImage} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <StyledSubHeading>At SRVIS, we're more than just your developers - we're your strategic partners, leveraging technology to boost your growth and success.</StyledSubHeading>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)